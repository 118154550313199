@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
  -moz-transform: translate3d($x, $y, $z);
  -o-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}

.App-header {
  background-color: #a00 !important;
  // transform: translate3d(10px, 20px, 30px);
  // @include translate3d(10px, 20px, 30px);

  // transform: rotate3d(80, 90, 20, 45deg);
}

.App-header {
  // perspective: 1000px;
  // perspective-origin: 50% 50%;
  // transform: scale3d(1, 1, 1) rotateX(-21deg) rotateY(33deg) rotateZ(0deg) translate3d(0px, 0px, 0px) skew(0deg , 0deg);
}
