.rc-slider-mark-text {
  // margin-bottom: 0 !important;
  // margin-top: -5px !important;
  margin-bottom: -10px !important;
  margin-top: -15px !important;
}
.rc-slider-mark-text-active {
  color: #000;
  font-weight: bold;
  margin-bottom: -4 5% !important;
}
.rc-slider-middle {
  padding: 2px 4px;
  margin-left: -4px;
  background-color: orange; // #af0;
  // margin-bottom: -10% !important;
  margin-bottom: 0 !important;
  margin-top: -4px !important;
}
.rc-slider-active {
  padding: 2px 4px;
  margin-left: -4px;
  background-color: yellow; // #af0;
  margin-bottom: 0 !important;
  margin-top: -4px !important;
}
.rc-slider-edited {
  padding: 2px 4px;
  margin-left: -4px;
  background-color: #1aff81; // #af0;
  margin-bottom: 0 !important;
  margin-top: -4px !important;
}
