.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  z-index: 1;
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spin-back {
  0% { transform: rotate(-360deg) scaleX(-1); }
  100% { transform: rotate(0deg) scaleX(-1); }
}

.spin-back {
  animation: spin-back 1s linear infinite;
}

.spin {
  animation: spin 1s linear infinite;
}

.flip-horizontal {
  transform: scaleX(-1);
}
.flip-vertical {
  transform: scaleY(-1);
}

.inset-right-middle {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-100%, -50%);
}
